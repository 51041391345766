import React from "react";
import "survey-react/survey.css";
import { HandsSVG } from "../../assets/images/svgimages";
import {ThankYouPageProps} from "./thankyoupage.props";
import {ThankYouPageState} from "./thankyoupage.state";

export default class ThankYouPage extends React.Component <ThankYouPageProps, ThankYouPageState> {

    public constructor(props: ThankYouPageProps) {
        super(props);
        this.state = {
        };
        this.setState(this.state);
    }

    public render(): JSX.Element {

        return (
            <div className="result-page" id="thank-you" >
                
                <HandsSVG brand={this.props.brand} ></HandsSVG>

                <h1>Grazie</h1>
                <h2>La tua opinione per noi è importante.</h2>
                <p>La Survey è stata compilata correttamente</p>

                {/* TODO: mettere url in config */}
                <a href={this.props.brand.code == "L" ? "https://www.lexus.it" : "https://www.toyota.it/"}>Visita il nostro sito</a>
            </div>

        );
    }
}