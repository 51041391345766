export class Subject<T> {
    private listeners: SubjectHandler<T>[] = [];
    public invoke(data: T) {
        this.listeners.forEach(l => l.handler(data));
    }
    public subscribe(handler: SubjectHandler<T>) {
        if(handler === null) { return; }
        let result = this.listeners.filter(l => l.id === handler.id);
        if(result === null || result.length === 0) {
            this.listeners.push(handler);
        }
    }
    public unsubscribe(id: string) {
        this.listeners = this.listeners.filter(l => l.id !== id);
    }
}
export class SubjectHandler<T> {
    public id: string;
    public handler: (data: T) => void;
}