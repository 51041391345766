export interface IAppConfiguration {
    apiUrl: string;
    privacyPolicyToyota: string;
    privacyPolicyLexus: string;
}

export class Environment {

    private static configuration: IAppConfiguration = null;

    public constructor() {
    }

    public static async initConfig() {
        if(Environment.configuration != null)
            return;
        let configFile = process.env.REACT_APP_ENV == null ? "config.json" : "config." + process.env.REACT_APP_ENV + ".json";
        let response = await fetch(new Request('./' + configFile, {
            method: 'GET',
        }));
        let json = await response.text();
        Environment.configuration = JSON.parse(json);
    }

    public get apiUrl(): string {
        return Environment.configuration.apiUrl;
    }
    public get environment(): string {
        return  process.env.REACT_APP_ENV;
    }
    public get privacyPolicyToyota(): string {
        return Environment.configuration.privacyPolicyToyota;
    }
    public get privacyPolicyLexus(): string {
        return Environment.configuration.privacyPolicyLexus;
    }
}