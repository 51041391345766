import ReactModal from 'react-modal';
import React from "react";
import {ModalComponentProps} from "./modal.component.props";
import {ModalComponentState, ModalType} from "./modal.component.state";
import SurveyMessage from '../surveyMessage/survey.message';
import { Reason } from '../../model/reason';

export class ModalComponent extends React.Component<ModalComponentProps, ModalComponentState> {

    constructor(props: ModalComponentProps) {
        super(props);
        this.state = {
            showModal: false
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal(message: string, type: ModalType = ModalType.info, title: string = '', reason: Reason = Reason.Unknown) {
        this.setState({
            showModal: true,
            title: title,
            message: message,
            type: type,
            reason: reason
        });
    }

    handleCloseModal() {
        this.setState({showModal: false});
    }

    render() {
        return (
            <div>
                {this.state.reason != Reason.Unknown &&(
                        <SurveyMessage brand={this.props.brand} isCompiled={this.state.reason == Reason.Completed} isExpired= {this.state.reason == Reason.Forbidden} notExist={this.state.reason == Reason.ResourceNotFound}/>
                    )
                }
                {this.state.reason == Reason.Unknown &&(       
                    // <ReactModal
                    //     isOpen={this.state.showModal}
                    //     contentLabel="onRequestClose Example"
                    //     onRequestClose={this.handleCloseModal}
                    //     ariaHideApp={false}
                    //     className="Modal"
                    //     overlayClassName="Overlay">
                    //     <h5>{this.state.title}</h5>
                    //     <p>{this.state.message}</p>
                    //     <button onClick={this.handleCloseModal}>Ok</button>
                    // </ReactModal>
                    <SurveyMessage brand={this.props.brand} isCompiled={false} isExpired= {false} notExist={true} />
                )}
            </div>
        );
    }
}