import {HttpService} from "./base/http.service";
import * as Survey from "survey-react";
import {TryCatch} from "../common/decorator/try.catch.decorator";
import {SurveyDto} from "../model/survey-dto";

export class SurveyService extends HttpService {

    private baseUrl : string = '/survey';

    public async getSurvey(eventId: string): Promise<SurveyDto> {
        let json = await this.get<any>(this.baseUrl + "/" + eventId);
        return json;
    }
    public async saveSurvey(survey: SurveyDto): Promise<SurveyDto> {
        let json = await this.post<any>(this.baseUrl, survey);
        return json;
    }
    public async completeSurvey(survey: SurveyDto): Promise<SurveyDto> {
        let json = await this.post<any>(this.baseUrl + "/complete", survey);
        return json;
    }
}