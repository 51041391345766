
export const WarningSVG = (props: any) => (
    <svg width="257" height="257" viewBox="0 0 257 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_4_1000)">
        <rect x="10" y="10" width="223" height="223" rx="70" fill="url(#paint0_linear_4_1000)" />
        <rect x="10.5" y="10.5" width="222" height="222" rx="69.5" stroke="#F4F4F4" />
        </g>
        <g filter="url(#filter1_d_4_1000)">
        <rect x="24" y="24" width="196" height="196" rx="60" fill="#F8F8F8" />
        <rect x="24.5" y="24.5" width="195" height="195" rx="59.5" stroke={props.brand.primaryColor} />
        </g>
        <path
        d="M68.3827 175.124C58.2418 175.124 54.1423 167.958 59.1049 159.054L112.83 65.6775C118.008 56.7742 126.207 56.7742 131.17 65.6775L184.895 159.054C189.858 167.958 185.758 175.124 175.617 175.124H68.3827Z"
        stroke={props.brand.primaryColor} stroke-linecap="round" stroke-linejoin="round" />
        <path d="M70.1089 163.397L122.324 72.8436L174.323 163.397H70.1089Z" stroke={props.brand.primaryColor} stroke-linecap="round"
        stroke-linejoin="round" />
        <path d="M122.324 103.028V132.561" stroke={props.brand.primaryColor} stroke-linecap="round" />
        <path
        d="M123.834 145.808C123.834 146.676 123.187 147.545 122.108 147.545C121.245 147.545 120.382 146.893 120.382 145.808C120.382 144.939 121.029 144.07 122.108 144.07C123.187 144.07 123.834 144.939 123.834 145.808Z"
        fill="white" stroke={props.brand.primaryColor} stroke-miterlimit="10" />
        <defs>
        <filter id="filter0_d_4_1000" x="0.333333" y="0.333333" width="256.143" height="256.143"
            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
            <feMorphology radius="2.7619" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4_1000" />
            <feOffset dx="6.90476" dy="6.90476" />
            <feGaussianBlur stdDeviation="6.90476" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.814583 0 0 0 0 0.816 0 0 0 0 0.85 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_1000" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_1000" result="shape" />
        </filter>
        <filter id="filter1_d_4_1000" x="4" y="4" width="244" height="244" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
            <feMorphology radius="4" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4_1000" />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="10" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.814583 0 0 0 0 0.816 0 0 0 0 0.85 0 0 0 0.52 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_1000" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_1000" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_4_1000" x1="121.5" y1="10" x2="121.5" y2="233" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        </defs>
    </svg>
)

export const CheckSVG = (props: any) => (
    <svg width="257" height="257" viewBox="0 0 257 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_4_1001)">
        <rect x="10" y="10" width="223" height="223" rx="70" fill="url(#paint0_linear_4_1001)" />
        <rect x="10.5" y="10.5" width="222" height="222" rx="69.5" stroke="#F4F4F4" />
        </g>
        <g filter="url(#filter1_d_4_1001)">
        <rect x="24" y="24" width="196" height="196" rx="60" fill="#F8F8F8" />
        <rect x="24.5" y="24.5" width="195" height="195" rx="59.5" stroke={props.brand.primaryColor} />
        </g>
        <circle cx="121.084" cy="122.084" r="58.584" stroke={props.brand.primaryColor} />
        <path fill-rule="evenodd" clip-rule="evenodd"
        d="M182.097 69.7822C182.645 70.2324 182.725 71.0418 182.274 71.59L125.223 142.797C124.986 143.085 124.636 143.256 124.263 143.265C123.89 143.275 123.531 143.122 123.28 142.845L92.7325 109.243C92.2553 108.718 92.294 107.906 92.8189 107.429C93.3438 106.951 94.1561 106.99 94.6333 107.515L124.181 140.018L180.289 69.9594C180.74 69.4112 181.549 69.3319 182.097 69.7822Z"
        fill={props.brand.primaryColor} stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <defs>
        <filter id="filter0_d_4_1001" x="0.333333" y="0.333333" width="256.143" height="256.143"
            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
            <feMorphology radius="2.7619" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4_1001" />
            <feOffset dx="6.90476" dy="6.90476" />
            <feGaussianBlur stdDeviation="6.90476" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.814583 0 0 0 0 0.816 0 0 0 0 0.85 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_1001" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_1001" result="shape" />
        </filter>
        <filter id="filter1_d_4_1001" x="4" y="4" width="244" height="244" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
            <feMorphology radius="4" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4_1001" />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="10" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.814583 0 0 0 0 0.816 0 0 0 0 0.85 0 0 0 0.52 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_1001" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_1001" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_4_1001" x1="121.5" y1="10" x2="121.5" y2="233" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        </defs>
    </svg>

)

export const HandsSVG = (props: any) => (
    <svg width="257" height="257" viewBox="0 0 257 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_4_999)">
        <rect x="10" y="10" width="223" height="223" rx="70" fill="url(#paint0_linear_4_999)" />
        <rect x="10.5" y="10.5" width="222" height="222" rx="69.5" stroke="#F4F4F4" />
        </g>
        <g filter="url(#filter1_d_4_999)">
        <rect x="24" y="24" width="196" height="196" rx="60" fill="#F8F8F8" />
        <rect x="24.5" y="24.5" width="195" height="195" rx="59.5" stroke={props.brand.primaryColor} />
        </g>
        <g filter="url(#filter2_d_4_999)">
        <rect x="24" y="24" width="196" height="196" rx="60" fill="#F8F8F8" />
        <rect x="24.5" y="24.5" width="195" height="195" rx="59.5" stroke={props.brand.primaryColor} />
        </g>
        <path d="M156.083 143.069H173.04" stroke={props.brand.primaryColor} stroke-miterlimit="10" />
        <path d="M104.619 164.365C102.253 164.956 96.9287 165.153 93.5767 159.435L79.3798 135.182H70.901" stroke={props.brand.primaryColor}
        stroke-miterlimit="10" />
        <path d="M118.224 139.323L126.703 154.506" stroke={props.brand.primaryColor} stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M105.999 149.182L111.52 158.647" stroke={props.brand.primaryColor} stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M137.745 157.858C135.379 158.844 130.647 160.816 126.703 154.309" stroke={props.brand.primaryColor}
        stroke-miterlimit="10" />
        <path d="M120.393 161.999C120.393 161.999 115.266 165.548 111.52 158.647" stroke={props.brand.primaryColor}
        stroke-miterlimit="10" />
        <path d="M70.7039 86.0844H98.7034" stroke={props.brand.primaryColor} stroke-miterlimit="10" />
        <path d="M50 78H70.3095V144.055H50" stroke={props.brand.primaryColor} stroke-miterlimit="10" />
        <path d="M193.35 83.7183H173.237V149.774H193.547" stroke={props.brand.primaryColor} stroke-miterlimit="10" />
        <path
        d="M158.38 146.434L134.984 102.45C133.013 102.253 123.154 101.464 123.154 101.464L109.745 115.07C98.5062 123.746 92.3937 110.929 92.3937 110.929L109.745 88.4506C115.464 81.1549 121.379 81.1549 121.379 81.1549H147.407L172.843 92.197"
        stroke={props.brand.primaryColor} stroke-miterlimit="10" />
        <path d="M159.238 144.055C158.252 147.013 151.548 164.168 143.069 149.182L131.238 130.45" stroke={props.brand.primaryColor}
        stroke-miterlimit="10" stroke-linecap="round" />
        <path
        d="M190.589 133.407C190.589 134.196 189.998 134.985 189.012 134.985C188.223 134.985 187.434 134.393 187.434 133.407C187.434 132.619 188.026 131.83 189.012 131.83C189.998 131.83 190.589 132.422 190.589 133.407Z"
        fill={props.brand.primaryColor} stroke={props.brand.primaryColor} stroke-miterlimit="10" />
        <path
        d="M55.9155 127.492C55.9155 128.281 55.3239 129.07 54.338 129.07C53.5493 129.07 52.7606 128.478 52.7606 127.492C52.7606 126.703 53.3521 125.915 54.338 125.915C55.1268 125.915 55.9155 126.506 55.9155 127.492Z"
        fill={props.brand.primaryColor} stroke={props.brand.primaryColor} stroke-miterlimit="10" />
        <defs>
        <filter id="filter0_d_4_999" x="0.333333" y="0.333333" width="256.143" height="256.143"
            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
            <feMorphology radius="2.7619" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4_999" />
            <feOffset dx="6.90476" dy="6.90476" />
            <feGaussianBlur stdDeviation="6.90476" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.814583 0 0 0 0 0.816 0 0 0 0 0.85 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_999" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_999" result="shape" />
        </filter>
        <filter id="filter1_d_4_999" x="4" y="4" width="244" height="244" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
            <feMorphology radius="4" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4_999" />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="10" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.814583 0 0 0 0 0.816 0 0 0 0 0.85 0 0 0 0.52 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_999" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_999" result="shape" />
        </filter>
        <filter id="filter2_d_4_999" x="4" y="4" width="244" height="244" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
            <feMorphology radius="4" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4_999" />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="10" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.814583 0 0 0 0 0.816 0 0 0 0 0.85 0 0 0 0.52 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_999" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_999" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_4_999" x1="121.5" y1="10" x2="121.5" y2="233" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        </defs>
    </svg>
)
