export function Loading() {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalValue = descriptor.value;

        // Replace the original function with a wrapper
        descriptor.value = async function (...args: any[]) {
            let component = this["component"] != null ? this.component : this;
            component.setState({ isLoading: true });
            try {
                var result = await originalValue.apply(this, args);
                return result;
            } finally {
                component.setState({ isLoading: false });
            }
        };
    };
}