import React from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import {SurveyPageState} from "./survey.page.state";
import {IViewModelInterface} from "../../common/base.component";

export class SurveyPageViewModel implements IViewModelInterface<SurveyPageState> {

    public state: SurveyPageState;
    public constructor(state: SurveyPageState) {
        this.state = state;
    }
}