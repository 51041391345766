import App from "../../app";
import { Error } from "../../model/error";
import { Reason } from "../../model/reason";

export function TryCatch(options?: {
    rethrowError?: boolean;
    errorMessage?: string;
    errorTitle?: string;
    errorReason?: Reason;
    showSuccess?: boolean;
    successMessage?: string;
    successTitle?: string;
}) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalValue = descriptor.value;
        // Replace the original function with a wrapper
        descriptor.value = async function (...args: any[]) {
            options = options ? options : {} as any;
            options.rethrowError = options.rethrowError == null ? false : options.rethrowError;
            try {          
                var result = await originalValue.apply(this, args);            
                if (options.successMessage != null || options.showSuccess) {
                    App.modalSbj.invoke({
                        message: options.successMessage,
                        title: options.successTitle,
                    });
                }
                return result;
            } catch (exc) {
                options.errorReason = exc
                if (exc == null) {
                    return null;
                }
                if (exc instanceof Error) {
                    options.errorMessage = exc.message;
                    options.errorTitle = "Errore";
                    options.errorReason = exc.reason;  
                }
                App.modalSbj.invoke({
                    message: options.errorMessage,
                    title: options.errorTitle,
                    reason: exc.Reason
                });
                if (options.rethrowError) {
                    throw exc;
                }
                return null;
            }
            finally {
                this.setState({ isLoading: false });
            }
        };
    };
}