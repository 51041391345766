import React from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import {SurveyMessageProps} from "./survey.message.props";
import {SurveyMessageState} from "./survey.message.state";
import {SurveyService} from "../../services/survey.service";
import {BaseComponent} from "../../common/base.component";
import { WarningSVG, CheckSVG } from "../../assets/images/svgimages";

export default class SurveyMessage extends React.Component <SurveyMessageProps, SurveyMessageState> {

    public constructor(props: SurveyMessageProps) {
        super(props);
        this.state = {
        };
        this.setState(this.state);
    }

    public render(): JSX.Element {
        return (
            <div className={"container"}>
                {this.props.isCompiled && (
                    <div className="result-page" id="already-done" >
                        
                        <CheckSVG brand={this.props.brand}></CheckSVG>

                        <h1>Fatto</h1>
                        <h2>Ci hai già dato la tua opinione</h2>
                        <p>Grazie mille. Il tuo contributo migliorerà i nostri servizi</p>

                        {/* TODO: inserire la url in config */}
                        <a href={this.props.brand.code == "L" ? "https://www.lexus.it" : "https://www.toyota.it/"}>Visita il nostro sito</a>
                    </div>
                )}               
                {this.props.isExpired && (
                    <div className="result-page" id="error" >
                        
                        <WarningSVG brand={this.props.brand}></WarningSVG>

                        <h1>OPS!</h1>
                        <h2>Sarà per la prossima volta</h2>
                        <p>La Survey che stai cercando è scaduta</p>

                        {/* TODO: inserire la url in config */}
                        <a href={this.props.brand.code == "L" ? "https://www.lexus.it" : "https://www.toyota.it/"}>Visita il nostro sito</a>
                    </div>
                )}
                {this.props.notExist && (
                    <div className="result-page" id="error" >
                        
                        <WarningSVG brand={this.props.brand}></WarningSVG>

                        <h1>OPS!</h1>
                        <h2>Sarà per la prossima volta</h2>
                        <p>La Survey che stai cercando risulta inesistente</p>

                        {/* TODO: inserire la url in config */}
                        <a href={this.props.brand.code == "L" ? "https://www.lexus.it" : "https://www.toyota.it/"}>Visita il nostro sito</a>
                    </div>
                )}
            </div>
        );
    }
}