import React from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import {SurveyPageProps} from "./survey.page.props";
import {SurveyPageState} from "./survey.page.state";
import {SurveyService} from "../../services/survey.service";
import {BaseComponent} from "../../common/base.component";
import {SurveyPageViewModel} from "./survey.page.vm";
import {TryCatch} from "../../common/decorator/try.catch.decorator";
import {SurveyDto} from "../../model/survey-dto";
import {ISpinnerStyleProps, ISpinnerStyles, IStyleFunctionOrObject, Spinner, SpinnerSize} from "@fluentui/react";
import {SurveyResponse} from "../../model/survey-response";
import ThankYouPage from "../../components/thankyoupage/thankyoupage"
import SurveyMessage from "../../components/surveyMessage/survey.message";
import { Loading } from "../../common/decorator/loading.decorator";
import { Backdrop, CircularProgress } from "@mui/material";

const spinnerStyle: IStyleFunctionOrObject<ISpinnerStyleProps, ISpinnerStyles> = {
    circle: {
        borderColor: "var(--primary-color) rgb(255, 204, 200) rgb(255, 204, 200)"
    },
    label: {
        color: "var(--primary-color)"
    }
}

export default class SurveyPage extends BaseComponent<SurveyPageProps, SurveyPageState, SurveyPageViewModel> {
    private surveyService: SurveyService = new SurveyService();
    protected viewModel: SurveyPageViewModel;

    public constructor(props: SurveyPageProps) {
        super(props, (state) => new SurveyPageViewModel(state));

        this.state = {
            isShowError: false,
            errorMessage: null,
            isLoading: true,
            jsonSurvey: new Survey.Model(),
            showThankyou: false,
            showNotFound: false,
            surveyLoaded: false
        };
        this.viewModel = new SurveyPageViewModel(this.state);
        this.setState(this.state);
        this.onCurrentPageChanging = this.onCurrentPageChanging.bind(this);
        this.onCompleteSurvey = this.onCompleteSurvey.bind(this);
    }

    @TryCatch({rethrowError: false, errorMessage: 'Errore Generico', errorTitle: 'Errore Titolo'})
    public async componentDidMount() {

        if (this.props.eventId != null && this.props.eventId != "") {
            let survey = await this.surveyService.getSurvey(this.props.eventId);
            let sModel = this.convertToData(survey);

            let {vote, question} = this.loadUserVoteAndQuestion(sModel);
            let isPartiallyComplteted = survey.responses && survey.responses.length > 0;

            /*Modifichiamo la survey se:
                1. il voto non è null ed è un valore nell'intervallo [0,10]
                2. la prima domanda della prima pagina è di tipo 'rating'
                3. la survey non è parzialmente completata, se lo è ignoriamo il valore di v
            */
            if (vote != null && question != null && !isPartiallyComplteted) {
                survey.responses = [{
                    question: question.title,
                    responseId: question.name,
                    responseValue: vote.toString(),
                    responseText: vote.toString()
                }];
                // salvo la survey
                await this.surveyService.saveSurvey(survey);
                // ricarico il model
                sModel = this.convertToData(survey);
            }

            sModel.showCompletedPage = false;
            sModel.onComplete.add((result) => {
                this.setState({
                    isLoading: false,
                    showThankyou: true
                });
            });

            this.setState({
                jsonSurvey: sModel,
                survey: survey,
                showThankyou: false,
                surveyLoaded: true
            });
        } else
            this.setState({
                showThankyou: false,
                showNotFound: true,
                surveyLoaded: false
            });

    }

    public render(): JSX.Element {
        console.log(this.state.jsonSurvey);
        return (
            <>
                <div>
                    {!this.state.isLoading && this.state.surveyLoaded && (
                        <>
                            {this.state.jsonSurvey && this.state.jsonSurvey.isShowProgressBarOnTop && 
                                <div className="progress">
                                    <div className="bar" style={{ width: this.state.jsonSurvey.progressValue + "%" }}>
                                    </div>
                                    <span className="text">{this.state.jsonSurvey.progressText}</span>
                                </div>
                            }
                            <Survey.Survey
                                model={this.state.jsonSurvey}
                                onCurrentPageChanging={this.onCurrentPageChanging}
                                onComplete={this.onCompleteSurvey}
                                //onValidateQuestion={surveyValidateQuestion}
                                //saveState={this.saveState}
                                //onValueChanged={onValueChanged}
                            />
                            {this.state.jsonSurvey && this.state.jsonSurvey.isShowProgressBarOnBottom && 
                                <div className="progress">
                                    <div className="bar" style={{ width: this.state.jsonSurvey.progressValue + "%" }}>
                                    </div>
                                    <span className="text">{this.state.jsonSurvey.progressText}</span>
                                </div>
                            }
                        </>
                    )}
                </div>
                {this.state.showNotFound && (
                    <SurveyMessage brand={this.props.brand} isCompiled={false} isExpired= {false} notExist={true}/>
                )}
                {this.state.showThankyou && (
                    <ThankYouPage brand={this.props.brand}></ThankYouPage>
                )}
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={this.state.isLoading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </>
        );
    }

    @Loading()
    private async onCurrentPageChanging(res: any, options: any) {
        if (options.isNextPage) {
            await this.saveState(res);
        } else if (options.isPreviousPage) {

        }
    }

    @Loading()
    //@TryCatch({rethrowError: false, errorMessage: 'Errore Generico', errorTitle: 'Errore Titolo'})
    private async onCompleteSurvey(survey) {
        await this.surveyService.completeSurvey(this.convert(survey))
    }

    //@TryCatch({rethrowError: false, errorMessage: 'Errore Generico', errorTitle: 'Errore Titolo'})
    private async saveState(survey) {
        await this.surveyService.saveSurvey(this.convert(survey));
    }

    private convert(survey: any): SurveyDto {
        let result = this.state.survey;
        result.survey = JSON.stringify(survey);
        result.page = survey.currentPageNo;

        result.communicationChannel = this.getQuerystringParam('c');

        let rawJson = survey.data;
        let props = Object.keys(rawJson);
        let responses: SurveyResponse[] = [];

        let surveyElements = JSON.parse(JSON.stringify(survey)).pages?.flatMap(page => page.elements)
        props.forEach(p => 
        {
            let responseText = survey.data[p];
            let surveyElement = surveyElements?.find(element => element.name == p.toString());
            // TODO: valutare necessità di gestire altri type
            if(surveyElement.type == 'radiogroup')
            {
                responseText = surveyElement.choices.find(choise => choise.value == survey.data[p]).text;
            }
            responses.push({
                question: surveyElement.title.default == undefined ? surveyElement.title : surveyElement.title.default,
                responseId: p.toString(),
                responseValue: survey.data[p],
                responseText: responseText
            })
        });
        result.responses = responses;
        return result;
    }

    private convertToData(survey: SurveyDto): Survey.Model {
        if(survey.survey == null) { return new Survey.Model(); }
        let result = new Survey.Model(survey.survey);
        if(survey.responses == null) { return result; }
        survey.responses.forEach(r => result.setValue(r.responseId, r.responseValue));
        if(survey.page != null) {
            while(survey.page != result.currentPageNo) {
                if(survey.page > result.currentPageNo)
                    result.nextPage();
                else
                    result.prevPage();
            }
        }
        return result;
    }

    private getQuerystringParam(param: string): string {
        let value: string = "-";
        let params = new URLSearchParams(window.location.search);
        value = params.get(param);
        return value;
    }

    private getValidatedVoteParam(): number | null {
        let value: string | null = this.getQuerystringParam('v');
        if (value != null) {
            let vote: number = parseInt(value, 10);
            if (!isNaN(vote) && vote >= 0 && vote <= 10) {
                return vote;
            }
        }
        return null;
    }

    private getFirstRatingQuestion(surveyModel: Survey.Model): any {
        if (surveyModel.pages.length > 0 && surveyModel.pages[0].elements.length > 0) {
            let question = surveyModel.pages[0].elements[0];
            if (question.getType() === 'rating') {
                return question;
            }
        }
        return null;
    }

    private loadUserVoteAndQuestion(surveyModel: Survey.Model): { vote: number | null, question: any | null } {
        let vote = this.getValidatedVoteParam();
        let question = this.getFirstRatingQuestion(surveyModel);
        return { vote, question };
    }
}

//function surveyValidateQuestion(s: any, options: any) {

    // if (options.value === undefined)
    //     options.error = "Campo obbligatorio"
//}
