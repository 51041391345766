import { Reason } from "../../model/reason";

export enum ModalType {
    info,
    warning,
    error
}
export interface ModalSubject {
    showModal?: boolean;
    title?: string;
    message?: string;
    type?: ModalType;
    reason?: Reason;
}
export interface ModalComponentState extends ModalSubject {
    showModal?: boolean;
}