import React from "react";
import {ModalSubject} from "../components/modal/modal.component.state";
import App from "../app";

export interface IViewModelInterface<TState> {
}

export abstract class BaseComponent<P, S, VM extends IViewModelInterface<S>> extends React.Component<P, S> {
    private readonly viewModelFactory : (state: S) => VM;

    protected abstract viewModel: VM;
    protected constructor(props: P, vm: (state: S) => VM) {
        super(props);
        this.viewModelFactory = vm;
    }

    public setState(state: S) {
        super.setState(state);
        this.viewModel = this.viewModelFactory(state);
    }

    public showModal(data: ModalSubject) {
        App.modalSbj.invoke(data);
    }
}