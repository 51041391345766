import React, {Component, RefObject} from "react";
import "./app.scss";
import SurveyPage from "./pages/survey/survey.page";
import {Subject} from "./common/subject";
import {ModalComponent} from "./components/modal/modal.component";
import {ModalSubject} from "./components/modal/modal.component.state";
import {Environment} from "./environment/environment";
import {Brand} from "./model/brand";

export interface IAppProps {

}

export interface IAppState {
    startup: boolean;
    brand: Brand;
    eventId: string;
    privacyPolicyUrl: string;
}

class App extends  React.Component<IAppProps, IAppState, Component> {

    public static modalSbj = new Subject<ModalSubject>();
    private showModal: boolean = false;
    private modal: RefObject<ModalComponent> = null;
    private environment: Environment = new Environment();

    constructor(props: IAppProps) {
        super(props);

        this.state = {
            brand: new Brand(),
            eventId: "",
            startup: false,
            privacyPolicyUrl: ""
        };
        this.modal = React.createRef<ModalComponent>();
    };

    public componentDidMount() {
        // TODO: gestire la favicon in base alla URL
        // const favicon = getFaviconEl();
        // if(window.location.hostname == "survey.lexus.it")
        //     favicon.setAttribute("href", "faviconLexus.ico");
        // if(window.location.hostname == "survey.toyota.it")
        //     favicon.setAttribute("href", "faviconToyota.ico");

        let eventId = this.getQuerystringParam('i');
        let brandCode = this.getQuerystringParam('b');
        let brand = new Brand();
        switch (brandCode) {
            case "T":
                document.querySelector('html').dataset.theme = 'theme-toyota';
                brand.code = brandCode;
                brand.name = "Toyota";
                brand.primaryColor = "#bb261a";
                brand.secondaryColor = "#e3e3e3";
                break;
            case "L":
                document.querySelector('html').dataset.theme = 'theme-lexus';
                brand.code = brandCode;
                brand.name = "Lexus";
                brand.primaryColor = "#000";
                brand.secondaryColor = "#e3e3e3";
                break;
            default:
                brand.code = brandCode;
                brand.name = "Toyota";
                brand.primaryColor = "#bb261a";
                brand.secondaryColor = "#e3e3e3";
                document.querySelector('html').dataset.theme = 'theme-toyota';
                break;
        }

        App.modalSbj.subscribe({ id: 'app', handler: (data) => {
            this.modal.current.handleOpenModal(data.message, data.type, data.title, data.reason);
        }});

        Environment.initConfig().then(() => {
            this.setState({
                eventId: eventId,
                brand: brand,
                startup: true,
                privacyPolicyUrl: brand.code == "T" ? this.environment.privacyPolicyToyota : brand.code == "L" ? this.environment.privacyPolicyLexus : ""
            });
        });
    }

    private getQuerystringParam(param: string): string {
        let value: string = "-";
        let params = new URLSearchParams(window.location.search);
        value = params.get(param);
        return value;
    }

    public render() {
        let eventId = this.getQuerystringParam('i');
        return (
            <>
                <div className={this.state.brand.code == "L" ? "brand-lexus" : "brand-toyota"} >
                    {this.state.brand.code == "T" && 
                        <div className="header-t"></div>
                    }
                    {this.state.brand.code == "L" && 
                        <div className="header-l"></div>
                    }
                    {this.state.startup && 
                        <SurveyPage brand={this.state.brand} eventId = {eventId}/>
                    }
                    <ModalComponent brand={this.state.brand} ref={this.modal}></ModalComponent>
                    <div className="footer">
                        {this.state.privacyPolicyUrl && <><a href={this.state.privacyPolicyUrl} target="_blank">Privacy Policy</a><br/><br/></>}
                        Copyright © Toyota
                        <br/>
                        <br/>
                        Toyota Motor Italia S.p.A. a socio unico - Via Kiiciro Toyoda, 2 - 00148 Roma (RM)
                        Cap. Soc. € 38.958.000,00 i.v. - Cod. Fisc. ed Iscr. al Registro Imprese di Roma n° 03926291000 - R.E.A. di Roma n°
                        713082 - Partita IVA n° 03926291000
                        Controllante: Toyota Motor Europe NV/SA (Art. 2497 sexies Cod. Civ.)
                    </div>
                </div>
            </>
        );
    }
}

function getFaviconEl() {
    return document.getElementById("favicon");
}

export default App