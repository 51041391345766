import {Environment} from "../../environment/environment";

export class HttpService {

    private environment: Environment = new Environment();

    public async post<T>(endpoint: string, data: object, headers: Headers = null): Promise<T> {
        let response = await fetch(this.request(endpoint, 'POST', data, headers));
        let json = await response.json();
        if(response.status >= 400 && response.status <= 599) {
            throw json;
        }
        return json as T;
    }

    public async get<T>(endpoint: string, headers: Headers = null): Promise<T> {
        let response = await fetch(this.request(endpoint, 'GET', null, headers));
        let json = await response.json();
        if(response.status >= 400 && response.status <= 599) {
            throw json;
        }
        return json as T;
    }

    public async put<T>(endpoint: string, data: object, headers: Headers = null): Promise<T> {
        let response = await fetch(this.request(endpoint, 'PUT', data, headers));
        let json = await response.json();
        if(response.status >= 400 && response.status <= 599) {
            throw json;
        }
        return json as T;
    }

    public async delete<T>(endpoint: string, headers: Headers = null): Promise<T> {
        let response = await fetch(this.request(endpoint, 'DELETE', null, headers));
        let json = await response.json();
        if(response.status >= 400 && response.status <= 599) {
            throw json;
        }
        return json as T;
    }

    public async patch<T>(endpoint: string, data: any, headers: Headers = null): Promise<T> {
        let response = await fetch(this.request(endpoint, 'PATCH', data, headers));
        let json = await response.json();
        if(response.status >= 400 && response.status <= 599) {
            throw json;
        }
        return json as T;
    }

    private request(endpoint: string, method: string, data: any, headers: Headers): Request {
        return new Request(this.environment.apiUrl + endpoint, {
            method: method,
            headers: this.getHeaders(headers),
            body: data != null ? JSON.stringify(data) : null
        });
    }

    private getHeaders(headers: Headers) : Headers {
        let result = headers == null ? new Headers() : headers;
        result.append('Content-Type', 'application/json');
        result.append('Accept', 'application/json');
        result.append('Access-Control-Allow-Origin', '*');
        return result;
    }
}
